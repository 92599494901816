.PageSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 600px;
  width: 100vw;
}

.PageSection .section-header {
  font-size: 1.8em;
}

.PageSection .section-sub-header {
  /* font-size: 1.8em; */
  margin-top: 8px;
  text-align: center;
  color: gray;
}

@media (max-width: 480px) {
  .PageSection {
    font-size: 0.8em;
  }
}
