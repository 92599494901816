.rider-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  max-width: 1200px;
  border: 1px solid #999;
  border-radius: 5px;
}

.rider-card .row-1,
.rider-card .row-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
}

.rider-card .row-1 {
  border-bottom: 1px solid #ccc;
}

.rider-card .row-1 .left .name {
  font-weight: 500;
}

.rider-card .row-1 .right .status {
  font-weight: 500;
  padding: 5px;
  border-radius: 5px;
  user-select: none;
}

.rider-card[data-status="pending"] .row-1 .right .status {
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
}

.rider-card[data-status="enroute"] .row-1 .right .status {
  background-color: rgba(255, 166, 0, 0.96);
  color: white;
}

.rider-card[data-status="pickedup"] .row-1 .right .status {
  background-color: rgb(0, 181, 255);
  color: white;
}

.rider-card[data-status="droppedoff"] .row-1 .right .status {
  background-color: rgba(0, 128, 0, 0.5);
  color: white;
}

.rider-card[data-status="droppedoff"] {
  text-decoration: line-through;
  background-color: rgba(220, 220, 220, 0.5);
}

.rider-card .row-2 {
  align-items: center;
}

.rider-card .row-2 select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 0;
  border-radius: 5px;
  margin: 10px 0;
}
