.custom-tabs {
  display: flex;
  column-gap: 10px;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  width: inherit;
  max-width: 400px;
  border-radius: 20px;
  background-color: #eeeeee;
}

.custom-tabs > * {
  flex: 1;

  cursor: pointer;
  padding: 10px;
  border-radius: 20px;
  border: 5px solid transparent;
  background-color: inherit;
  user-select: none;
  font-size: 1.1em;
  font-weight: bold;
}

.custom-tabs > *[data-selected="true"] {
  border-color: rgba(255, 120, 0, 1);
}

.custom-tabs > *:hover[data-selected="false"] {
  border-color: rgba(255, 120, 0, 0.18);
}
