* {
  transition-duration: 100ms;
}

:root {
  --color-primary: #222;
  --color-secondary: rgba(255, 120, 0, 1);
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  row-gap: 20px;
}

main .App {
  scrollbar-gutter: stable;
}

@media (max-width: 600px) {
  .App .left .title {
    font-size: 2em;
    font-weight: 600;
  }
}
